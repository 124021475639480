import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-modal-routing-3'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Language from "../components/Language"
import Depoimento from "../components/Depoimento"
import LogoSection from "../components/LogoSection"
import Noticia from "../components/Noticia"

//Content
import texts, { parceiros } from "../components/Conquistas/content"

const Conquistas = () => {
  return (
    <Layout>
      <Seo title="Conquistas" />
      <Container className='container'>
        <Title>{Language(texts).titulos.depoimentos}</Title>
        <Content>
          {Language(texts).depoimentos.map(
            item => {
              return (
                <Depoimento
                  nome={item.nome}
                  empresa={item.empresa}
                  texto={item.texto}
                />
              );
            }
          )}
        </Content>
      </Container>
      <Container>
        <Title>{Language(texts).titulos.numeros}</Title>
        <Content>
          <Link
            to="/numeros"
            state={{ 
              data: Language(texts).numeros2017, 
              title: 'Game Jam Plus 2017'
            }}
            asModal
          >
            <EditionButton>2017</EditionButton>
          </Link>
          <Link
            to="/numeros"
            state={{ data: Language(texts).numeros2018, title: 'Game Jam Plus 2018' }}
            asModal
          >
            <EditionButton>2018</EditionButton>
          </Link>
          <Link
            to="/numeros"
            state={{ data: Language(texts).numeros2019, title: 'Game Jam Plus 2019' }}
            asModal
          >
            <EditionButton>2019</EditionButton>
          </Link>
          <Link
            to="/numeros"
            state={{ data: Language(texts).numeros2021, title: 'Game Jam Plus 20/21' }}
            asModal
          >
            <EditionButton>20/21</EditionButton>
          </Link>
        </Content>
      </Container>
      <div>

      </div>
      <LogoSection
        title={Language(texts).titulos.parceiros}
        list={parceiros}
      />
      <Container>
        <Title>{Language(texts).titulos.imprensa}</Title>
        <Content>
          {Language(texts).noticias.map(
            item => {
              return (
                <Noticia
                  logo={item.logo}
                  manchete={item.manchete}
                  texto={item.texto}
                  data={item.data}
                  link={item.link}
                />
              );
            }
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export default Conquistas

const Container = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 4rem;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 32px 0 0;
`

const EditionButton = styled.div`
  height: 120px;
  width: 120px;
  margin: 1rem;
  background: var(--pink);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--white);
  font-family: var(--primary);
  font-size: 32px;
`