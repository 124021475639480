// Noticias
import unityN from '../../assets/images/noticias/unity.png';
import uol from '../../assets/images/noticias/uol.png';
import terra from '../../assets/images/noticias/terra.png';
import sebraeN from '../../assets/images/noticias/sebrae.png';
import segs from '../../assets/images/noticias/segs.png';
import governo from '../../assets/images/noticias/governo.png';
import gamasutra from '../../assets/images/noticias/logo_gamasutra.png';
// Parceiros
import unicef from '../../assets/images/parceiros/unicef.png';
import nuuvem from '../../assets/images/parceiros/nuuvem.png';
import lumberyard from '../../assets/images/parceiros/lumberyard.png';
import wildlife from '../../assets/images/parceiros/wildlife.png';
import weDoLogos from '../../assets/images/parceiros/weDoLogos.png';
import abraGames from '../../assets/images/parceiros/abraGames.png';
import firjan from '../../assets/images/parceiros/firjan.png';
import rioCriativo from '../../assets/images/parceiros/rioCriativo.png';
import stone from '../../assets/images/parceiros/stone.png';
import senac from '../../assets/images/parceiros/senac.png';
import minc from '../../assets/images/parceiros/minc.png';
import projecao from '../../assets/images/parceiros/projecao.png';
import positivo from '../../assets/images/parceiros/positivo.png';
import pucSp from '../../assets/images/parceiros/pucSp.png';
import brde from '../../assets/images/parceiros/brde.png';
import fundoNacional from '../../assets/images/parceiros/fundoNacional.png';
import akom from '../../assets/images/parceiros/akom.png';
// Logo Patrocinadores
import poki from '../../assets/images/logos/patrocinadores/poki.png'
import google from '../../assets/images/logos/patrocinadores/google.png'
import sebrae from '../../assets/images/logos/patrocinadores/sebrae.png'
import unity from '../../assets/images/logos/patrocinadores/unity.png'
import uberstrategist from '../../assets/images/logos/patrocinadores/uberstrategist2.png'
import ebg from '../../assets/images/logos/patrocinadores/ebg.png'
import voodoo from '../../assets/images/logos/patrocinadores/voodoo.png'
import igda from '../../assets/images/logos/patrocinadores/igda.png'
import allCorrect from '../../assets/images/logos/patrocinadores/allCorrect.png'
import gazeus from '../../assets/images/logos/patrocinadores/gazeus.png'
import bigFestival from '../../assets/images/logos/patrocinadores/bigFestival.png'
import bemobi from '../../assets/images/logos/patrocinadores/bemobi.png'
import hermitCrab from '../../assets/images/logos/patrocinadores/hermitCrab.png'
import g4c from '../../assets/images/logos/patrocinadores/g4c.png'
import tgibrasil from '../../assets/images/logos/patrocinadores/tgibrasil.png'
import hackingrio from '../../assets/images/logos/patrocinadores/hackingrio.png'
import flamengo from '../../assets/images/logos/patrocinadores/flamengo.png'
import sapiens from '../../assets/images/logos/patrocinadores/sapiens.png'
import gameplan from '../../assets/images/logos/patrocinadores/gameplan.png'
import consuladoUK from '../../assets/images/logos/patrocinadores/consuladoUK.png'


const Texts = {
  PT: {
    titulos: {
      depoimentos: "Depoimentos",
      numeros: 'Alguns dos nossos números',
      parceiros: "Quem já nos apoiou",
      imprensa: "Imprensa"
    },
    numeros2017: {
      sobrevivencia: {
        titulo: 'Taxa de Sobrevivência',
        part: 'Projetos',
        partn: '62',
        etapa1: '1 mês após 1ª etapa',
        etapa1n: '45% (29)',
        final: 'Até a final (4 meses)',
        finaln: '17% (11)'
      },
      aceleracao: {
        titulo: 'Aceleração e Mentoria',
        part: 'Projetos Acelerados	',
        partn: '11',
        horas: 'Horas de Mentoria',
        horasn: '275'
      },
      partbr: {
        titulo: 'Participantes por Região (Brasil)',
        norte: 'Região Norte',
        norten: '--',
        nordeste: 'Região Nordeste',
        nordesten: '--',
        centro: 'Região Centro-Oeste',
        centron: '25',
        sudeste: 'Região Sudeste',
        sudesten: '298',
        sul: 'Região Sul',
        suln: '100'
      },
      finalbr: {
        titulo: 'Finalistas por Região (Brasil)',
        norte: 'Região Norte',
        norten: '--',
        nordeste: 'Região Nordeste',
        nordesten: '--',
        centro: 'Região Centro-Oeste',
        centron: '2',
        sudeste: 'Região Sudeste',
        sudesten: '5',
        sul: 'Região Sul',
        suln: '4'
      },
      premiobr: {
        titulo: 'Prêmios por Região (Brasil)',
        norte: 'Região Norte',
        norten: '--',
        nordeste: 'Região Nordeste',
        nordesten: '--',
        centro: 'Região Centro-Oeste',
        centron: '0',
        sudeste: 'Região Sudeste',
        sudesten: '3',
        sul: 'Região Sul',
        suln: '0'
      }
    },
    numeros2018: {
      sobrevivencia: {
        titulo: 'Taxa de Sobrevivência',
        part: 'Projetos',
        partn: '81',
        etapa1: '1 mês após 1ª etapa',
        etapa1n: '62% (50)',
        final: 'Até a final (4 meses)',
        finaln: '37% (30)'
      },
      aceleracao: {
        titulo: 'Aceleração e Mentoria',
        part: 'Projetos Acelerados	',
        partn: '30',
        horas: 'Horas de Mentoria',
        horasn: '460'
      },
      partbr: {
        titulo: 'Participantes por Região (Brasil)',
        norte: 'Região Norte',
        norten: '8',
        nordeste: 'Região Nordeste',
        nordesten: '121',
        centro: 'Região Centro-Oeste',
        centron: '35',
        sudeste: 'Região Sudeste',
        sudesten: '216',
        sul: 'Região Sul',
        suln: '90'
      },
      finalbr: {
        titulo: 'Finalistas por Região (Brasil)',
        norte: 'Região Norte',
        norten: '2',
        nordeste: 'Região Nordeste',
        nordesten: '13',
        centro: 'Região Centro-Oeste',
        centron: '2',
        sudeste: 'Região Sudeste',
        sudesten: '8',
        sul: 'Região Sul',
        suln: '4'
      },
      premiobr: {
        titulo: 'Prêmios por Região (Brasil)',
        norte: 'Região Norte',
        norten: '1',
        nordeste: 'Região Nordeste',
        nordesten: '4',
        centro: 'Região Centro-Oeste',
        centron: '3',
        sudeste: 'Região Sudeste',
        sudesten: '1',
        sul: 'Região Sul',
        suln: '5'
      }
    },
    numeros2019: {
      sobrevivencia: {
        titulo: 'Taxa de Sobrevivência',
        part: 'Projetos',
        partn: '197',
        etapa1: '1 mês após 1ª etapa',
        etapa1n: '56% (110)',
        final: 'Até a final (4 meses)',
        finaln: '29% (48)'
      },
      aceleracao: {
        titulo: 'Aceleração e Mentoria',
        part: 'Projetos Acelerados	',
        partn: '58',
        horas: 'Horas de Mentoria',
        horasn: '700'
      },
      partbr: {
        titulo: 'Participantes por Região (Brasil)',
        norte: 'Região Norte',
        norten: '97',
        nordeste: 'Região Nordeste',
        nordesten: '223',
        centro: 'Região Centro-Oeste',
        centron: '59',
        sudeste: 'Região Sudeste',
        sudesten: '300',
        sul: 'Região Sul',
        suln: '136'
      },
      finalbr: {
        titulo: 'Finalistas por Região (Brasil)',
        norte: 'Região Norte',
        norten: '7',
        nordeste: 'Região Nordeste',
        nordesten: '16',
        centro: 'Região Centro-Oeste',
        centron: '4',
        sudeste: 'Região Sudeste',
        sudesten: '14',
        sul: 'Região Sul',
        suln: '4'
      },
      premiobr: {
        titulo: 'Prêmios por Região (Brasil)',
        norte: 'Região Norte',
        norten: '3',
        nordeste: 'Região Nordeste',
        nordesten: '4',
        centro: 'Região Centro-Oeste',
        centron: '4',
        sudeste: 'Região Sudeste',
        sudesten: '0',
        sul: 'Região Sul',
        suln: '3'
      }
    },
    numeros2021: {
      sobrevivencia: {
        titulo: 'Taxa de Sobrevivência',
        part: 'Projetos',
        partn: '281',
        etapa1: '1 mês após 1ª etapa',
        etapa1n: 'Em breve',
        final: 'Até a final (4 meses)',
        finaln: 'Em breve'
      },
      aceleracao: {
        titulo: 'Aceleração e Mentoria',
        part: 'Projetos Acelerados	',
        partn: '92',
        horas: 'Horas de Mentoria',
        horasn: '1200'
      },
      partbr: {
        titulo: 'Participantes por Região',
        norte: 'Brasil',
        norten: '415',
        nordeste: 'Américas',
        nordesten: '203',
        centro: 'Europa',
        centron: '65',
        sudeste: 'Ásia',
        sudesten: '367',
        sul: 'África',
        suln: '40'
      },
      finalbr: {
        titulo: 'Finalistas por Região',
        norte: 'Região Norte',
        norten: 'Em breve',
        nordeste: 'Região Nordeste',
        nordesten: 'Em breve',
        centro: 'Região Centro-Oeste',
        centron: 'Em breve',
        sudeste: 'Região Sudeste',
        sudesten: 'Em breve',
        sul: 'Região Sul',
        suln: 'Em breve'
      },
      premiobr: {
        titulo: 'Prêmios por Região',
        norte: 'Região Norte',
        norten: 'Em breve',
        nordeste: 'Região Nordeste',
        nordesten: 'Em breve',
        centro: 'Região Centro-Oeste',
        centron: 'Em breve',
        sudeste: 'Região Sudeste',
        sudesten: 'Em breve',
        sul: 'Região Sul',
        suln: 'Em breve'
      }
    },
    depoimentos: [
      {
        nome: "Sandro Manfredini",
        empresa: "Presidente da Abragames",
        texto: "Sugiro que vocês apoiem demais esse projeto, ele é parte do embrião do desenvolvimento nacional."
      }, {
        nome: "Arthur Protásio",
        empresa: "Flabeware",
        texto: "A Game Jam Plus é um movimento incrível no Brasil, porque é na verdade a oportunidade que tanta gente procura para se envolver com o mercado de jogos."
      }, {
        nome: "Marcos Castro",
        empresa: "YouTuber",
        texto: "Nós temos pessoas muito talentosas, criativas e a gente tem sim que fomentar isso tudo que está acontecendo nessa cena, que ainda está começando, mas é muito promissora."
      }, {
        nome: "Sérgio Sá Leitão",
        empresa: "Secretário da Cultura do Estado de São Paulo",
        texto: "Se mais pessoas abraçarem essa causa, ela pode chegar em um país inteiro, então junte-se a nós!"
      }, {
        nome: "Mario R. Kroll",
        empresa: "Presidente da UberStrategist Inc.",
        texto: "GameJam + é uma organização fantástica, composta por voluntários com grandes corações, que faz muito por aqueles que buscam oportunidades na indústria de videogames."
     }
    ],
    noticias: [
      {
        link: "https://unity3d.com/pt/events/game-jam-plus-regional-2018",
        logo: unityN,
        manchete: "Game Jam Plus Regional 2018",
        texto: "Junte-se a Alexandre Kikuchi, evangelista da Unity, neste evento especial na Game Jam Plus patrocinado por Unity! Ele estará abrindo a cerimônia como o orador principal e falará sobre a Unity Asset Store, Certificações, Unity Connect e uma introdução às novas ferramentas da Unity 2018.",
        data: ""
      }, {
        link: "http://gamereporter.uol.com.br/game-jam/",
        logo: uol,
        manchete: "Já estão abertas as inscrições para a segunda edição da Game Jam +, o maior evento de desenvolvimento da América Latina",
        texto: "Após passar por seis capitais e realizar uma final emocionante no Rock in Rio 2017, chegou a vez de começar a segunda edição do Game Jam+, o maior evento de desenvolvimento de jogos do Brasil.",
        data: "22 de jun 2018"
      }, {
        link: "https://www.terra.com.br/noticias/dino/gamejamplus-olimpiada-brasileira-de-desenvolvimento-de-jogos,5f9b1a1f47ae4252a038bfa2998ce405psifmprj.html",
        logo: terra,
        manchete: "GameJamPlus - Olimpíada Brasileira de desenvolvimento de jogos",
        texto: "Após primeira edição em 6 capitais e com final no Rock in Rio, estão abertas as inscrições para segunda edição do maior evento de desenvolvimento de jogos da AL.",
        data: "20 de jul 2018"
      }, {
        link: "http://www.ma.agenciasebrae.com.br/sites/asn/uf/MA/sao-luis-recebe-game-jam-neste-final-de-semana,4237046caf1d4610VgnVCM1000004c00210aRCRD",
        logo: sebraeN,
        manchete: "São Luís recebe Game Jam+ neste final de semana",
        texto: "O Sebrae no Maranhão está apoiando a competição voltada para desenvolvedores de games por meio de seu projeto de economia criativa.",
        data: "26 de jul 2018"
      }, {
        link: "http://cultura.gov.br/uma-brincadeira-que-gera-negocios/",
        logo: governo,
        manchete: "Uma brincadeira que gera negócios",
        texto: "Final de olimpíada de desenvolvimento de jogos reúne, em novembro, no RJ, 28 equipes de 14 estados. Objetivo é que games virem negócio.",
        data: "24 out 2018"
      }, {
        link: "https://www.segs.com.br/eventos/142503-final-da-olimpiada-brasileira-de-games-sera-neste-sabado-03-11-na-casa-firjan",
        logo: segs,
        manchete: "Final da olimpíada brasileira de games será neste sábado (03/11) na Casa Firjan",
        texto: "Casa Firjan, espaço de inovação recém-inaugurado em Botafogo, no Rio de Janeiro, recebe neste sábado (3/11) a final da Game Jam+, olimpíada brasileira de desenvolvimento de jogos.",
        data: "5 de nov 2018"
      }, {
        link: "https://www.segs.com.br/eventos/142503-final-da-olimpiada-brasileira-de-games-sera-neste-sabado-03-11-na-casa-firjan",
        logo: gamasutra,
        manchete: "GameJam +, a Copa do Mundo de Desenvolvimento de Jogos, Anuncia os Vencedores do Evento deste Ano",
        texto: " GameJam +- uma maratona anual de desenvolvimento de videogame estabelecida para promover e orientar novos estúdios independentes e apoiar sua entrada na indústria global de videogame - tem o prazer de anunciar os vencedores do evento deste ano!",
        data: "5 de nov 2018"
      },
    ]
  },
  EN: {
    titulos: {
      depoimentos: "Testimonies",
      numeros: 'Some of our numbers',
      parceiros: "Who has already supported us",
      imprensa: "Press"
    },
    numeros2017: {
      sobrevivencia: {
        titulo: 'Survival rate',
        part: 'Projects',
        partn: '62',
        etapa1: '1 month after 1st stage',
        etapa1n: '45% (29)',
        final: 'Until the final (4 months)',
        finaln: '17% (11)'
      },
      aceleracao: {
        titulo: 'Acceleration and Mentoring',
        part: 'Accelerated Projects',
        partn: '11',
        horas: 'Hours of Mentoring',
        horasn: '275'
      },
      partbr: {
        titulo: 'Participants by Region (Brazil)',
        norte: 'North Region',
        norten: '--',
        nordeste: 'Northeast Region',
        nordesten: '--',
        centro: 'Midwest Region',
        centron: '25',
        sudeste: 'Southeast Region',
        sudesten: '298',
        sul: 'South Region',
        suln: '100'
      },
      finalbr: {
        titulo: 'Finalists by Region (Brazil)',
        norte: 'North Region',
        norten: '--',
        nordeste: 'Northeast Region',
        nordesten: '--',
        centro: 'Midwest Region',
        centron: '2',
        sudeste: 'Southeast Region',
        sudesten: '5',
        sul: 'South Region',
        suln: '4'
      },
      premiobr: {
        titulo: 'Awards by Region (Brazil)',
        norte: 'North Region',
        norten: '--',
        nordeste: 'Northeast Region',
        nordesten: '--',
        centro: 'Midwest Region',
        centron: '0',
        sudeste: 'Southeast Region',
        sudesten: '3',
        sul: 'South Region',
        suln: '0'
      }
    },
    numeros2018: {
      sobrevivencia: {
        titulo: 'Survival rate',
        part: 'Projects',
        partn: '81',
        etapa1: '1 month after 1st stage',
        etapa1n: '62% (50)',
        final: 'Until the final (4 months)',
        finaln: '37% (30)'
      },
      aceleracao: {
        titulo: 'Acceleration and Mentoring',
        part: 'Accelerated Projects',
        partn: '30',
        horas: 'Hours of Mentoring',
        horasn: '460'
      },
      partbr: {
        titulo: 'Participants by Region (Brazil)',
        norte: 'North Region',
        norten: '8',
        nordeste: 'Northeast Region',
        nordesten: '121',
        centro: 'Midwest Region',
        centron: '35',
        sudeste: 'Southeast Region',
        sudesten: '216',
        sul: 'South Region',
        suln: '90'
      },
      finalbr: {
        titulo: 'Finalists by Region (Brazil)',
        norte: 'North Region',
        norten: '2',
        nordeste: 'Northeast Region',
        nordesten: '13',
        centro: 'Midwest Region',
        centron: '2',
        sudeste: 'Southeast Region',
        sudesten: '8',
        sul: 'South Region',
        suln: '4'
      },
      premiobr: {
        titulo: 'Awards by Region (Brazil)',
        norte: 'North Region',
        norten: '1',
        nordeste: 'Northeast Region',
        nordesten: '4',
        centro: 'Midwest Region',
        centron: '3',
        sudeste: 'Southeast Region',
        sudesten: '1',
        sul: 'South Region',
        suln: '5'
      }
    },
    numeros2019: {
      sobrevivencia: {
        titulo: 'Survival rate',
        part: 'Projects',
        partn: '197',
        etapa1: '1 month after 1st stage',
        etapa1n: '56% (110)',
        final: 'Until the final (4 months)',
        finaln: '29% (48)'
      },
      aceleracao: {
        titulo: 'Acceleration and Mentoring',
        part: 'Accelerated Projects',
        partn: '58',
        horas: 'Hours of Mentoring',
        horasn: '700'
      },
      partbr: {
        titulo: 'Participants by Region (Brazil)',
        norte: 'North Region',
        norten: '97',
        nordeste: 'Northeast Region',
        nordesten: '223',
        centro: 'Midwest Region',
        centron: '59',
        sudeste: 'Southeast Region',
        sudesten: '300',
        sul: 'South Region',
        suln: '136'
      },
      finalbr: {
        titulo: 'Finalists by Region (Brazil)',
        norte: 'North Region',
        norten: '7',
        nordeste: 'Northeast Region',
        nordesten: '16',
        centro: 'Midwest Region',
        centron: '4',
        sudeste: 'Southeast Region',
        sudesten: '14',
        sul: 'South Region',
        suln: '4'
      },
      premiobr: {
        titulo: 'Awards by Region (Brazil)',
        norte: 'North Region',
        norten: '3',
        nordeste: 'Northeast Region',
        nordesten: '4',
        centro: 'Midwest Region',
        centron: '4',
        sudeste: 'Southeast Region',
        sudesten: '0',
        sul: 'South Region',
        suln: '3'
      }
    },
    numeros2021: {
      sobrevivencia: {
        titulo: 'Survival rate',
        part: 'Projects',
        partn: '281',
        etapa1: '1 month after 1st stage',
        etapa1n: 'Coming soon',
        final: 'Until the final (4 months)',
        finaln: 'Coming soon'
      },
      aceleracao: {
        titulo: 'Acceleration and Mentoring',
        part: 'Accelerated Projects',
        partn: '92',
        horas: 'Hours of Mentoring',
        horasn: '1200'
      },
      partbr: {
        titulo: 'Participants by Region',
        norte: 'Brazil',
        norten: '415',
        nordeste: 'Americas',
        nordesten: '203',
        centro: 'Europe',
        centron: '65',
        sudeste: 'Asia',
        sudesten: '367',
        sul: 'Africa',
        suln: '40'
      },
      finalbr: {
        titulo: 'Finalists by Region',
        norte: 'North Region',
        norten: 'Coming soon',
        nordeste: 'Northeast Region',
        nordesten: 'Coming soon',
        centro: 'Midwest Region',
        centron: 'Coming soon',
        sudeste: 'Southeast Region',
        sudesten: 'Coming soon',
        sul: 'South Region',
        suln: 'Coming soon'
      },
      premiobr: {
        titulo: 'Awards by Region',
        norte: 'North Region',
        norten: 'Coming soon',
        nordeste: 'Northeast Region',
        nordesten: 'Coming soon',
        centro: 'Midwest Region',
        centron: 'Coming soon',
        sudeste: 'Southeast Region',
        sudesten: 'Coming soon',
        sul: 'South Region',
        suln: 'Coming soon'
      }
    },
    depoimentos: [
      {
        nome: "Sandro Manfredini",
        empresa: "Abragames’ President",
        texto: "I' would highly suggest that you support this project, it is an essential part of the beginning of the country's game development."
      }, {
        nome: "Arthur Protásio",
        empresa: "Flabeware",
        texto: "Game Jam+ is an incredible movement in Brazil, it actually is the opportunity that so many are looking for to get involved with the game industry."
      }, {
        nome: "Marcos Castro",
        empresa: "YouTuber",
        texto: "We have very talented, creative people and we do need to foster all of this that is happening in this ecosystem, that it still growing but is highly promising."
      }, {
        nome: "Sérgio Sá Leitão",
        empresa: "São Paulo State's Culture Secretary",
        texto: "If more people embrace this cause, it can reach a whole country, so join us!"
      }, {
         nome: "Mario R. Kroll",
         empresa: "President, UberStrategist Inc.",
         texto: "GameJam+ is a fantastic organization, staffed by volunteers with huge hearts, that does so much for those seeking opportunities in the video game industry."
      }
    ],
    noticias: [
      {
        link: "https://unity3d.com/pt/events/game-jam-plus-regional-2018",
        logo: unityN,
        manchete: "Game Jam Plus Regionals 2018",
        texto: "Join Alexandre Kikuchi, Unity’s evangelist, in this special event in Game Jam+ sponsored by Unity! He will open the ceremony as the main speaker and will talk about Unity Asset Store, Certifications, Unity Connect and an introduction to Unity 2018 new tools.",
        data: ""
      }, {
        link: "http://gamereporter.uol.com.br/game-jam/",
        logo: uol,
        manchete: "Registration is open for the second edition of Game Jam+, the biggest game development event of Latin America",
        texto: "After being present in six capitals and holding an exciting final in Rock in Rio 2017, it is time to begin the second edition of Game Jam+, the biggest game development event in Brazil.",
        data: "June 22nd, 2018"
      }, {
        link: "https://www.terra.com.br/noticias/dino/gamejamplus-olimpiada-brasileira-de-desenvolvimento-de-jogos,5f9b1a1f47ae4252a038bfa2998ce405psifmprj.html",
        logo: terra,
        manchete: "GameJamPlus - Brazilian Game Development Olympics",
        texto: "After its first edition in 6 capitals and a grand finale in Rock in Rio, the registration for the second edition of the biggest game development event of Latam is now open.",
        data: "July 20th, 2018"
      }, {
        link: "http://www.ma.agenciasebrae.com.br/sites/asn/uf/MA/sao-luis-recebe-game-jam-neste-final-de-semana,4237046caf1d4610VgnVCM1000004c00210aRCRD",
        logo: sebraeN,
        manchete: "São Luís to host Game Jam+ site this weekend",
        texto: "Maranhão’s Sebrae is supporting a competition aimed for game developers through its creative economy project.",
        data: "July 26th, 2018"
      }, {
        link: "http://cultura.gov.br/uma-brincadeira-que-gera-negocios/",
        logo: governo,
        manchete: "A game that generates business",
        texto: "Final of Games Development Olympics gathers, in November, in Rio de Janeiro, 28 teams from 14 states. The goal is that the games developed become business projects.",
        data: "October 24th, 2018"
      }, {
        link: "https://www.segs.com.br/eventos/142503-final-da-olimpiada-brasileira-de-games-sera-neste-sabado-03-11-na-casa-firjan",
        logo: segs,
        manchete: "Brazilian game development Olympics final to happen this saturday (03/11) at Casa Firjan",
        texto: "Casa Firjan, newly opened innovation space in Botafogo, Rio de Janeiro, hosts this saturday (3/11) Game Jam+ final, the Brazilian game development Olympics.",
        data: "November 5th, 2018"
      }, {
        link: "https://www.gamasutra.com/view/pressreleases/381224/GameJam_the_Game_Development_World_Cup_Announces_the_Winners_of_this_Years_Event.php",
        logo: gamasutra,
        manchete: "GameJam+, the Game Development World Cup, Announces the Winners of this Year’s Event",
        texto: " GameJam+ — a yearly video game development marathon established to foster and mentor new independent studios and support their entry into the global video game industry — is thrilled to announce the winners for this year’s event!",
        data: "May 11, 2021"
      }, 
    ]
  }
}

export default Texts
        
export const parceiros = [
  { img: unity, link: "https://unity.com/" },
  { img: flamengo, link: "https://www.flamengo.com.br/" },
  { img: consuladoUK, link: "https://www.gov.uk/world/brazil" },
  { img: google, link: "https://about.google/" },
  { img: unicef, link: "" },
  { img: nuuvem, link: "https://www.nuuvem.com/" },
  { img: lumberyard, link: "" },
  { img: uberstrategist, link: "https://uberstrategist.com/" },
  { img: igda, link: "https://igda.org/" },
  { img: voodoo, link: "https://www.voodoo.io/" },
  { img: gazeus, link: "https://www.gazeus.com/" },
  { img: bigFestival, link: "https://www.bigfestival.com.br/index.html" },
  { img: wildlife, link: "" },
  { img: abraGames, link: "" },
  { img: firjan, link: "" },
  { img: allCorrect, link: "https://allcorrectgames.com/" },
  { img: sebrae, link: "https://m.sebrae.com.br/sites/PortalSebrae" },
  { img: bemobi, link: "https://www.bemobi.com.br/" },
  { img: hackingrio, link: "https://www.hackingrio.com/" },
  { img: senac, link: "" },
  { img: pucSp, link: "" },
  { img: minc, link: "" },
  { img: weDoLogos, link: "" },
  { img: projecao, link: "" },
  { img: positivo, link: "" },
  { img: brde, link: "" },
  { img: fundoNacional, link: "" },
  { img: poki, link: "https://poki.com/" },
  { img: g4c, link: "http://www.gamesforchange.org/" },
  { img: ebg, link: "https://escolabrasileiradegames.com.br/" },
  { img: hermitCrab, link: "https://hermitcrabstudio.com/" },
  { img: stone, link: "" },
  { img: tgibrasil, link: "https://www.youtube.com/user/TheGamerInsideBrasil" },
  { img: akom, link: "http://akomotion.com/" },
  { img: sapiens, link: "https://faculdadesapiens.edu.br/" },
  { img: rioCriativo, link: "" },
  { img: gameplan, link: "https://www.gameplan.com.br/" },
]