import React from 'react';
import styled from 'styled-components'

//Assets
import aspasTop from '../../assets/images/aspasTop.png'
import aspasBottom from '../../assets/images/aspasBottom.png'

const Depoimento = ({nome, texto, empresa}) => (
  <Container>
    <Name>{nome}</Name>
    <Text>{texto}</Text>
    <Company>{empresa}</Company>
  </Container>
)
export default Depoimento

const Container = styled.div`
  width: 270px;
  margin: 16px 8px;
  padding: 42px 16px;
  border: solid 8px var(--pink);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;

    &::before{
        content: "";
        width: 72px;
        height: 42px;
        background: url(${aspasBottom}) center center no-repeat;
        background-color: var(--white);
        background-size: contain;
        position: absolute;
        bottom: -8px;
        left: -8px;
    }

    &::after{
        content: "";
        width: 72px;
        height: 42px;
        background: url(${aspasTop}) center center no-repeat;
        background-color: var(--white);
        background-size: contain;
        position: absolute;
        top: -8px;
        right: -8px;
    }

  @media screen and (max-width: 768px) {
  }
`

const Name = styled.h3`
  font-family: var(--primary);
  font-size: 22px;
  color: var(--blue);
  position: relative;
  /* z-index: 1; */

  @media screen and (max-width: 768px) {
  }
`

const Text = styled.p`
  margin: 16px 0;
  font-family: var(--regular);
  font-size: 16px;
  color: var(--pink);
  line-height: 20px;

  @media screen and (max-width: 768px) {
  }
`

const Company = styled.h4`
  font-family: var(--primary);
  font-size: 16px;
  color: var(--blue);

  @media screen and (max-width: 768px) {
  }
`