import React from 'react';
import styled from 'styled-components'

const Noticia = ({ link, logo, manchete, texto, data }) => (
  <Container>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Content>
        <LogoBox>
          <Logo src={logo} alt="" />
        </LogoBox>
        <Title>{manchete}</Title>
        <Text>{texto}</Text>
        <Date>{data}</Date>
      </Content>
    </a>
  </Container>
)

export default Noticia

const Container = styled.div`
  width: 340px;
  margin: 64px 24px 16px;
  padding: 72px 16px 16px;
  background: var(--green);
  border-radius: 16px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 300px;
    margin: 64px 10px 16px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  text-align: center;
`

const LogoBox = styled.div`
  padding: 18px;
  background: var(--green);
  border: solid 8px var(--white);
  border-radius: 50%;
  position: absolute;
  top: -58px;
  z-index: 1;

  &::before{
    content: '';
    width: 88px;
    height: 88px;
    background: var(--white);
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 44px);
    left: calc(50% - 44px);
    z-index: -1;
  }
`

const Logo = styled.img`
  width: 64px;
`

const Title = styled.h3`
  font-family: var(--bold);
  font-size: 16px;
  line-height: 20px;
`

const Text = styled.p`
  font-family: var(--regular);
  font-size: 14px;
  line-height: 18px;
  margin: 12px 0;
`

const Date = styled.h4`
  font-family: var(--primary);
  font-size: 16px;
  line-height: 20px;
`